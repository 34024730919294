.banners-content{
    padding-top: 30px;    
    .container-fluid{
        padding-left: 18px; 
        padding-right: 18px;

        [class^=col]{
            padding-right: 5px;
            padding-left: 5px;

        }
        figure{
            border-radius: $border-radius;
            overflow: hidden;
            margin-bottom: 0px;
            width: 100%;
            position: relative;


            img{
                width: 100%;
                height: auto;
            }
            &.mt-image{
                margin-top: 10px;
            }
        }
        .banner-caption{
            position: absolute;
            width: 100%;
            z-index: 99;

            h2{
                font-family: $font-family-base-bold;
                margin: 0;
                small{
                    display: block;
                    font-family: $font-family-base;
                    font-size: 1rem;
                }
            }
            h4{
                color: $body-color-secondary;             
                
            }


            &.left-caption{
                top: 50%;
                transform: translateY(-50%);
                text-align: left;
                left: 30px;

            }
            &.top-caption{
                top: 30px;
                transform: translateX(-50%);
                text-align: center;
                left: 50%;
            }
            &.right-caption{
                right: 30px;
                transform: translateY(-50%);
                text-align: right;
                top: 50%;
            }
            &.bottom-caption{
                bottom: 70px;
                transform: translateX(-50%);
                text-align: center;
                left: 50%;
            }
            &.center-caption{
                top: 45%;
                transform: translate(-50% , -50%);
                text-align: center;
                left: 50%;
            }
            &.left-bottom-caption{
                bottom: 30px;
                transform: translateY(-30%);
                text-align: left;
                left: 30px;
            }
        }
            .hover-link{    
                transform: translateY(-100px);
                transition: 1.2s ease-out;
                opacity: 0;
                margin-top: 3px;
            }

      
    .main-banner:hover:not(.no-zoom) .banner-image img {
        -webkit-transform: scale3d(1.1,1.1,1);
        transform: scale3d(1.1,1.1,1);
    }
        
    }
}



.full-width-banner{
    margin-bottom: 30px;

    .banner-image{
        border-radius: $border-radius;
        overflow: hidden;
        margin-bottom: 0;
        // height: 270px;

        a{
            display: block;
        }
    }
}

.full-screen-banner{
    margin-bottom: 30px;

    .banner-image{
        border-radius: $border-radius;
        overflow: hidden;
        margin-bottom: 0;
        a{
            display: block;
        }
    }
}

.group-banners{
   .imagespace{
       margin-bottom: 30px;
   }
}
.banner-image{
    position: relative;
    border-radius: 0;
    overflow: hidden;
    margin-bottom: 0;
    img{
        width: 100%;
    }
    .over{
         
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        overflow: hidden;
        transform: translateY(-100px);
        transition: 1.2s ease-out;
        opacity: 0;
        background-color:  #dadadacf;
        .btn-bottom{
           position: absolute;
           bottom: 24px;
        }
        .btn-center{
            position: absolute;
            bottom: 60px;
        }
        .btn-top{
            position: absolute;
            top: 106px;
        }
        .btn-left-bottom{
            position: absolute;
            bottom: 10px;
            left: 20px;
        }
        .btn-left{
            position: absolute;
            bottom: 58px;
            left: 17px;
        }
        .btn-right{
            position: absolute;
            bottom: 58px;
            right: 17px;
        }
    }
    &:hover{
            .hover-link{ 
            opacity: 1;
            transition-timing-function: ease-in;
            transform: translateY(0px) !important;
            -webkit-transform: translateY(0px) !important;
            transition: 0.4s;
            }
        .over{
        
            opacity: 1;
            transition-timing-function: ease-in;
            transform: translateY(0px) !important;
            -webkit-transform: translateY(0px) !important;
            transition: 0.4s;
         
        }
    }
}


.fullwidth-banner{
     /* The image used */

  /* Set a specific height */
  height: 500px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  .parallax-banner-text{
      text-align: center;
      h2{
          font-size: 5rem;
          line-height: 1;
          font-family: $font-family-base-bold;
          color: $white;
          margin: 0;
      }
      h4{
          font-size: 40px;
          font-family: $font-family-base-bold;
          font-weight: $font-weight-bold;
          color: $white;   
          line-height: 1.5;
          margin: 0; 
      }
      .hover-link{    
        transform: translateY(-100px);
        transition: 1.2s ease-out;
        opacity: 0;
        margin-top: 15px;
    }
  }
  &:hover{
    .hover-link{ 
        opacity: 1;
        transition-timing-function: ease-in;
        transform: translateY(0px) !important;
        -webkit-transform: translateY(0px) !important;
        transition: 0.4s;
        }
  }
}







