// ... product area ...





.product-2x {
    
    margin-bottom: 30px;
    height: 343px;
    
     
    .featured-tag {
        position: absolute;
        top: 19px;
        left: 34px;
        background-color: $success;
        font-size: $font-size-base;
        color: color-yiq($primary);
        padding: 2px 10px;
        border-radius: $border-radius;
        z-index: 9;
     }

    .icon-liked {
        position: absolute;
        right: 26px;
        top: 20px;
        z-index: 9;
        .badge {
            font-family: "Roboto", sans-serif;
            position: absolute;
            right: 0;
            top: -5px;
            border-radius: $border-radius;
        }
        .icon {
            background-color:transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 45px;
            width: 45px;
            border: $border-width solid $border-color;
            border-radius: $border-radius;
            font-size: 1rem;
            color: $secondary;
            text-decoration: none;
            margin-left: 7px;
            margin-right: 7px;
            cursor: pointer;

            &:hover{
                background-color: $body-bg-secondary;
                color:color-yiq($body-bg-secondary);
            }
        }
        
    }
    article {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
        padding-top: 15px;
        height: 100%;
        display: inline-block;
        width: 100%;
        position: relative;
        background-color:$white;
        border:4px solid rgba(50,78,92, 0.49);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        border-radius: $border-radius-lg;


        .featured-tag {
            display: none;
            position: absolute;
            top: 19px;
            left: 34px;
            background-color: $success;
            font-size: $font-size-base;
            color: color-yiq($primary);
            padding: 2px 10px;
            border-radius: $border-radius;
            z-index: 9;
         }
    
        .icon-liked {
            display: none;
            position: absolute;
            right: 26px;
            top: 20px;
            z-index: 9;
            .badge {
                font-family: "Roboto", sans-serif;
                position: absolute;
                right: 0;
                top: -5px;
                border-radius: $border-radius;
            }
            .icon {
                background-color:transparent;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 45px;
                width: 45px;
                border: $border-width solid $border-color;
                border-radius: $border-radius;
                font-size: 1rem;
                color: $secondary;
                text-decoration: none;
                margin-left: 7px;
                margin-right: 7px;
                cursor: pointer;
    
                &:hover{
                    background-color: $body-bg-secondary;
                    color:color-yiq($body-bg-secondary);
                }
            }
            
        }
        .cart-detail-btn{
            display: none;
        }
        .thumb {
            position: absolute;
            right: 15px;
            bottom: 20px;
            height: auto;
            width: 35%;
            overflow: hidden;
            text-align: center;
        }
        .module{
            position: absolute;
            top: 12%;
        }
        .tag {
            font-size: $font-size-base;
            color: $body-color-secondary;
            overflow: hidden;
            margin-top: 20px;
            display: inline-block;
        }
        .title{
            display: flex;
            justify-content: center;
            align-items: center;
            color: $body-color;
            text-decoration: none;
            font-size:$font-size-lg;
            margin-top: 20px;
        }
        .price {
            font-size:($font-size-lg + 1rem);
            font-weight: $font-weight-bolder;
            color: $secondary;
            margin-top: 20px;
        }
        .list{
            display: flex;
            list-style: none;
            padding: 20px 0 0 0;
            li{
                padding-right: 64px;
                font-size: $font-size-lg;
                color: $body-color;
                small{
                    font-size: $font-size-base;
                    color: $body-color-secondary;
                }
            }
        }
        .buttons {
            float: left;
            width: 100%;
           
            .btn{
                width: 110px;
            }
        }

    }
}
