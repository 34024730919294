
.products-content{
    .products-area{
        .product{
            padding-bottom: 60px;
        }
    }
}
.product {        
 
    overflow: hidden;
    article {
        display: block;
        background-color: $white;
        border-radius: $border-radius-lg;
        // padding: 10px;
        width: 100%;
        position: relative;
        overflow: hidden;
     
        .thumb {
            height: 270px;
            overflow: hidden;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            position: relative;
        }
        .content{
            padding: 10px;
            .expand-detail{
                display: none;
            }
            .tag {
               
                font-family: $font-family-base;
                font-size: $font-size-sm;
                color:$body-color-secondary;
                text-transform: uppercase;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                -webkit-box-align: center;
                overflow: hidden;
                text-align: center;
                  
            }
            .title{
                font-family: $font-family-base-bold;
                display: flex;
                justify-content: center;
                align-items: center;
                color:$body-color;
                margin: 0;
                line-height: 1.5;
                text-decoration: none;
                font-size: 15px;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                -webkit-box-align: center;
                overflow: hidden;
                a{
                    display: block;
                    outline: none;
                }
            } 
            // p{
            //     display: none;
            // }
            .price {
                font-family: $font-family-base-bold;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: ($font-size-lg + 0.6);
                font-weight: $font-weight-bolder;
                color: $secondary;
    
                span {
                    color: $body-color-secondary;
                    text-decoration: line-through;
                    margin-left: 10px;
                    font-size: ($font-size-base + 0.2);
                    line-height: 1.5;
                }
            }

        }
       
        .product-hover {
            overflow: hidden;
            opacity: 0;
            -webkit-transition: 1.2s ease-out;
            transition: 1.2s ease-out;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #dadadaa6;
            border-radius: $border-radius-lg;
            height: 100%;
            width: 100%;
            z-index: 2;
            transform: translateY(-40px) !important;
            -webkit-transform: translateY(-40px) !important;
            .icons {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 60px;
                overflow: hidden;

              
                
                .icon{
                    position: relative;
                    background-color:  $secondary;
                    color: color-yiq($secondary);
                    display: flex;
                    width: 40px;
                    margin: 15px;
                    height: 40px;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                    overflow: hidden;  

                    &:hover{
                        background-color: $secondary;
                        color: color-yiq(darken($secondary, 10%));
                    }
                    
                    &::before{
                        background-color:darken( $secondary, 10%);
                    }
                }
            }
            .btn{
                width: calc(100% - 90px);
                margin: 0 auto;
                margin-top: 15px;
                padding: 0.6rem 1.6rem;
            
            }


        }
    
        &:hover {
            .product-hover {
                overflow: hidden;
                opacity: 1;
                transition-timing-function: ease-in;
                transform: translateY(0px) !important;
                -webkit-transform: translateY(0px) !important;
                /* A litttttle slower on the way in */
                transition: 0.4s;
                /* Move into place */
                transform: translateY(0);
        }
        }    
    }

 
}

.product-ad{
    height: 100%;
    article{
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 4px solid $border-color;
      
        picture{
            height: auto !important;
            display: block;
           margin: 10px; 
           text-align: center;
            width: 45%;
            overflow: hidden;
            position: relative;
            .product-hover {
                overflow: hidden;
                opacity: 0;
                -webkit-transition: 1.2s ease-out;
                transition: 1.2s ease-out;
                position: absolute;
                top: 0;
                left: 0;
                background-color: #dadadaa6;
                border-radius: $border-radius-lg;
                height: 100%;
                width: 100%;
                z-index: 2;
                transform: translateY(-40px) !important;
                -webkit-transform: translateY(-40px) !important;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                .icons {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-top: 60px;
                    overflow: hidden;
        
                  
                    
                    .icon{
                        position: relative;
                        background-color:  $secondary;
                        color: color-yiq($secondary);
                        display: flex;
                        width: 40px;
                        margin: 15px;
                        height: 40px;
                        justify-content: center;
                        align-items: center;
                        text-decoration: none;
                        overflow: hidden;  
        
                        &:hover{
                            background-color: $secondary;
                            color: color-yiq(darken($secondary, 10%));
                        }
                        
                        &::before{
                            background-color:darken( $secondary, 10%);
                        }
                    }
                }
                .btn{
                    width: 80%;
                    padding: 0.6rem 1.6rem;
                    margin-bottom: 15px;
                    padding: 0.6rem 0rem;
                     font-size: 13px;
                }
        
        
            }
        }
      .pro-options{
        margin-top: 5px;
        .selection-color{
            margin-bottom: 15px;
        }
      
        .selection-color , .selection-size{
            display: block;
           
            align-items: center;
            h4{
                margin-bottom: 0;
                line-height: 10px;
                font-size: $font-size-lg;
            }
            ul{
                list-style: none;
                margin-top: 10px;
                display: inline-block;
                margin-bottom: 0;
                padding: 0;

                li{
                    margin-right: 5px;
                    font-family: $font-family-base-bold;
                    color: $body-color-secondary;
                    line-height: 12px;
                    margin-top: 1px;
                    display: inline-block;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
      }
      .pro-sub-buttons .buttons .btn:first-child{
		padding-left: 0;
	}
    .pro-sub-buttons{
      
        .buttons{
           
            display: inline-block;
            .btn{
                font-size: 12px;
                padding: 0.6rem 0.5rem;
            }
            i.fas{
                margin-right: 5px;
            }
        }
    }
      
        .tag{
            font-family: $font-family-base;
            font-size: $font-size-sm;
            color:$body-color-secondary;
            text-transform: uppercase;
            padding-top: 0px;
            padding-left: 0;
            margin-top: 0;
            line-height: 1.5;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        }
        &:hover {
            .product-hover {
                overflow: hidden;
                opacity: 1;
                transition-timing-function: ease-in;
                transform: translateY(0px) !important;
                -webkit-transform: translateY(0px) !important;
                /* A litttttle slower on the way in */
                transition: 0.4s;
                /* Move into place */
                transform: translateY(0);
        }
    }

    }
     article .pre-tag{
        right: auto;
        left: 10px;
     }
     .icons{
        position: absolute;
        top: 0;
        right: 10px;
        margin: 10px 0px 0px 0;
     }
    .icon{
        position: relative;
        background-color:  $secondary;
        color: color-yiq($secondary) !important;
        display: flex;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        overflow: hidden;  
        margin-bottom: 10px;

        &:hover{
            background-color: $secondary;
            color: color-yiq(darken($secondary, 10%));
        }
        
        &::before{
            background-color:darken( $secondary, 10%);
        }
    }
    .detail{
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 55%;
        margin-left: 10px;
        .title{
            font-size: 15px;
            padding: 0;
            line-height: 1.5;
            white-space: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        margin-bottom: 0;
        }
        .discription{
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: 0;
            overflow: hidden;
        }
        .featured-detail{
        p, div{
            line-height: 1.5;
            white-space: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: 0;
        }
        }
        .price {
            font-family: $font-family-base-bold;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: ($font-size-lg + 0.6);
            font-weight: $font-weight-bolder;
            color: $secondary;

            span {
                color: $body-color-secondary;
                text-decoration: line-through;
                margin-left: 10px;
                font-size: ($font-size-base + 0.2);
                line-height: 1.5;
            }
           
        }
    }
}

.pro-content{
    overflow: hidden;
    padding-top: 100px;
    .slick-arrow{
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $body-bg-secondary !important;
        color: color-yiq($body-bg-secondary) !important;
        border: 1px solid $body-bg-secondary !important;
        border-radius: 0;
        margin-left: 1px;
        height: 37px;
        width: 38px;
        text-align: center;
        line-height: 38px;
        text-decoration: none;
        outline: none;
        opacity: 0;
        top: 48%;
        
        &:hover{
            background-color:$secondary;
        }
        &::after{
            background-color: darken($secondary, 10%);
            opacity: 0;
        }
        
    }
     
    .slick-prev {
            transition: 1.2s ease-in-out;
        transform: translateX(-100px) !important;
        -webkit-transform: translateX(-100px) !important;
        overflow: hidden;
        opacity: 0;
        position: absolute;
        left: -53px;
        &::before{
          
            margin-bottom: 2px;
            font-family: "revicons";
            line-height: 36px;
            font-size: 26px;
            opacity: 1 ;

        }
    }
    .slick-next {
        transition: 1.2s ease-in-out;
        
        transform: translateX(100px) !important;
        -webkit-transform: translateX(100px) !important;
        overflow: hidden;
        opacity: 0;
        position: absolute;
        right:-53px;
        &::before{
        
            margin-bottom: 2px;
            font-family: "revicons";
            line-height: 36px;
            font-size: 26px;
            opacity: 1;
        }
    }
   
 
    .general-product{
        overflow: hidden;
    &:hover {
        .slick-prev{
            transition: 0.4s ease-in-out;
            transform :translateX(0px) !important;
            -webkit-transform: translateX(0px) !important;
            opacity: 1 !important;
            overflow: hidden;
          }
          .slick-next{
            transition: 0.4s ease-in-out;
            transform :translateX(0px) !important;
            -webkit-transform: translateX(0px) !important;
            opacity: 1 !important;
            overflow: hidden;
          }
        }
  }
  .blog-carousel-js{
    &:hover .slick-arrow{
        transition: 0.4s ease-in-out;
        transform :translateX(0px) !important;
        -webkit-transform: translateX(0px) !important;
        opacity: 1 !important;
      }
  }

    .tabs-main{
      
        .nav{
            display: flex;
            justify-content: center;
            h4{
                position: absolute;
                right: 15px;
                top: 15px;
                font-size: 14px;
                font-weight: 600;
            }
        }
        .nav-link{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $font-family-base-bold;
            background-color:$body-bg-secondary;
            border:  $border-width solid $border-color;
            color: color-yiq($body-bg-secondary);
            text-transform: uppercase;
            width: 140px;
            height: 45px;
            margin-right: 30px;
            margin-bottom: 0;
            border-top-width: 0;
            border-left-width: 0;
            border-right-width: 0;
            border-bottom-width: 0;
            text-align: center;
            padding: 8px;
            box-shadow: none;
          
        }
        a.nav-link.active{

            color:color-yiq($secondary);
            background-color: $secondary !important;
            
            &::before {
                content: '';
                position: absolute;
                left: 62px;
                bottom: -18px;
                width: 0;
                height: 0;
                border: 8px solid transparent;
                border-top: 10px solid $secondary;
            }
        }
        a.nav-link.hover{

            color:color-yiq($secondary);
            background-color: $secondary !important;
            
            &::before {
                content: '';
                position: absolute;
                left: 63px;
                bottom: -16px;
                width: 0;
                height: 0;
                border: 8px solid transparent;
                border-top: 10px solid $secondary;
            }
        }

        &:hover {
            .slick-prev{
                transition: 0.4s ease-in-out;
                transform :translateX(0px) !important;
                -webkit-transform: translateX(0px) !important;
                opacity: 1 ;
                overflow: hidden;
              }
              .slick-next{
                transition: 0.4s ease-in-out;
                transform :translateX(0px) !important;
                -webkit-transform: translateX(0px) !important;
                opacity: 1 ;
                overflow: hidden;
              }
              &::after{
                -webkit-transform: scaleY(1);
                transform: scaleY(1);
              }
            }
    }
}


.slick-arrow:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% -100%;
    transform-origin: 50% -100%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
}
.slick-arrow:hover:after, 
.slick-arrow:focus:after, 
.slick-arrow:active:after {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1;
}   
.product article .btn-all{
    height: 370px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dadadaa6;
}


