// ...........footer.............

$bg-footer: $primary;
$bg-social: $primary;

.footer-five{
    padding-top: 45px;
    background-color:$bg-footer;
    color: color-yiq($bg-footer);
    .media{
        padding-bottom: 15px;
    }
    .logo {
        font-family: 'Pasajero';
        color: color-yiq($bg-footer);
        font-size: 34px;
        display: flex;
        align-items: center;
        line-height: normal;
        text-decoration: none;
        strong {
            color: $secondary;
        }
    }
    hr {
        margin-top: 5px;
        margin-bottom: 1rem;
        border: 0;
        border-top: $border-width solid $border-color;
        &:before {
            height: 1px;
            background-color: $secondary;
            content: "";
            position: absolute;
            width: 85px;
            margin-top: -1px;
        }
    }
    h5 {
        font-size: $font-size-base;
        text-transform: uppercase;
        margin: 0;
        text-transform: uppercase;
        margin-bottom: 15px;
    }  
    ul{
        li{
            a{
                .fa{
                    padding-right: 5px;
                }
                &:hover{
                    color: $secondary;
                }
            }
        }
    }  
    .footer-image{
        a{
            display: table-cell;
            padding-right: 5px;
        }
    }      
    .contact-list  {
        li {
            display: flex;
            align-items: flex-start;
            list-style: none;
            font-size: $font-size-base;
            margin-bottom: 15px;
            .fas {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: $font-size-lg;
                color: $secondary;
                height: 24px;
                width: 30px;
            }
            a {
                color: color-yiq($bg-footer);
            }
        }
    }
    .links-list{
        li{
            list-style-type: none;
            margin-bottom: 8px;
            a{
                display: block;
                font-size: $font-size-base;
                color: color-yiq($bg-footer);
                &:hover {
                    color: $secondary;
                }
            }
        }
    }
    .links-tag{
        li{
            list-style-type: none;
            float: left;
            
            a{
                display: block;
                font-size: 12px;
                color: color-yiq($bg-footer);
                border: $border-width solid $border-color;
                margin-right: 2px;
                padding-right: 4px;
                padding-left: 4px;
                margin-top: 2px;
                text-decoration: none;
                &:hover {
                    background-color: $secondary;
                    color: color-yiq($secondary);
                }
            }
        }
    }
        

    .link{
        padding: 0;
        list-style: none;
        display: flex;
        margin-top: 1rem;
        float: right;
        li{
            a{
                display: block;
                padding: 0px 7px;
                border-right: 1px solid;
                font-size: 14.8px;
                color: color-yiq($bg-social);
                line-height: 11px;
                text-decoration: none;
            }
        }
        li:first-child{
            a{
                padding-left: 0;
            }
        }
        li:last-child{
            a{
            border: none;
            padding-right: 0;
            }
        }
    }

    .payment-mthod{
        margin-bottom: 30px;
        .footer-image {
            .img-fluid{
                height: 20px;
            }
        }
    }

    // -----------------social
    .socials {
        display: block;
        .list {
            display: block;
            padding-left: 0;
            margin-bottom: 0;
            li{
                display: inline-block;
                a {
                    background-color: $primary;
                    color: color-yiq($primary);
                    font-size: $font-size-lg;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                    border-radius: $border-radius;
                    border: $border-width solid $border-color;

                    &:hover
                        {
                        background-color: $secondary;
                        color: color-yiq($secondary);
                        border-color: $secondary;
                    }
                }
            }
        }   
    }
      // -----------------social
     
      

.social-content{
    background-color:$bg-social;
    color: color-yiq($bg-social);
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 24px;
    .container{
        .social-div{
            background-color: $body-bg-secondary;
            padding: 15px;
        }
    }
    .social {
            display: flex;
            justify-content: flex-end;
            margin: 0;
            overflow: hidden;
            li{
                overflow: hidden;
                padding: 2px;
                a {
                    overflow: hidden;
                    background-color:$bg-social;
                    color: color-yiq($bg-social);
                    font-size: $font-size-lg;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                    border-radius: $border-radius;
                    border: $border-width solid $border-color;
               
                  &:hover{
                    background-color:$secondary;
                    color: color-yiq($secondary);
                  }
                }
            
            }
        }  
    .newsletter {
        
        .footer-info{
            color: color-yiq($secondary);
            a{
                color: color-yiq($secondary);
            }
            
        }
    }
    .footer-info{
        
        text-align: center;
    }
}  
}